import './App.css';
import { Page, PageSize, StyledProvider, } from './elements/MI-elements';
import { ThemeProvider } from '@mui/material';
import { DarkTheme, LightTheme } from './assets/theme';
import { RouterProvider,} from 'react-router-dom';
import { useContext, useEffect, } from 'react';
import TheRouter from './routes/TheRouter';
import TheContext from './Storage/theContext';
import LoadingLogo from './elements/LoadingLogo';
import AlertV2 from './components/TheAlert2';
import AddShiftDayBoxModalV2 from './components/ShiftComponent/ModalShiftAction/AddShiftDayBoxModalV2';
import { ImportImages } from './assets/Images/exportImages';
import { fetchApi } from './RES/API';
// import Test from './test/Test';

const App = () => {
  const { isDark, group } = useContext(TheContext);

  useEffect(() => {
    document.body.style.backgroundColor = isDark ? '#1E1F36' : '#E0E0E0';
    document.title = group?.name || "shift-manger";
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.href = ImportImages('logo');
    }
  }, [isDark, group]);

  useEffect(() => {
    const checkState = async () => {
      let res = await fetchApi('/', 'GET', null)
      console.log(res);

      if (!(res?.success)) {
        if (!(window.location.pathname == "/unavailable")) {
          window.location.href = '/unavailable';
          alert(res?.message)
          console.log(res);
        }
      }
    }
    checkState();
  }, []);

  // useEffect(() => {
  //   const checkState = async () => {
  //     // let res = await fetchApi('/', 'GET', null);
  //     const checkRespond = async () => {
  //       let res = await fetchApi('/', 'GET', null);
  //       console.log(res);
  //     }
  //     let observer = new MutationObserver(checkRespond);
  //     observer.observe(document, { childList: true, subtree: true });
  //     checkRespond(); // run once immediately
  //     let res = await fetchApi('/', 'GET', null)
  //     console.log(res);

  //     if (!(res?.success)) {
  //       if (!(window.location.pathname == "/unavailable")) {
  //         window.location.href = '/unavailable';
  //         alert(res?.message)
  //         console.log(res);
  //       }
  //     }
  //   }
  //   checkState();
  // }, []);

  function NetworkStatusComponent() {
    useEffect(() => {
      const handleOnline = () => {
        console.log("✅ online");
      };

      const handleOffline = () => {
        console.log("❌ offline");
      };

      // Check initial status
      if (navigator.onLine) {
        console.log("✅ You are online");
      } else {
        console.log("❌ You are offline");
      }

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);

    return null;
  }

  return (
    <StyledProvider theme={isDark}>
      <ThemeProvider theme={isDark ? DarkTheme : LightTheme}>
        <Page>
        {NetworkStatusComponent()}
          <RouterProvider
            future={'v7_startTransition'}
            router={TheRouter}
            fallbackElement={<PageSize><LoadingLogo /></PageSize>}
          />
          <AlertV2 isDark={isDark} />
          <AddShiftDayBoxModalV2 />
          {/* <Test/> */}
        </Page>
      </ThemeProvider>
    </StyledProvider>
  );
};

export default App;

/*
*code optimization*:
  Merge the similar codes in un optimized
*/